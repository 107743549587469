const DemoObject = [
  {
    imageURL:
      "https://images-na.ssl-images-amazon.com/images/I/61CVcwZ4SrL._SL1500_.jpg",
    features: ["3D", "AR", "VP"],
    platform: "shopify",
    title: "Kitchen Aid Mixer",
    subtitle: "Experience 3D & AR",
    link: "https://quickdemo.threekit.com/product/1",
  },
  {
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/demo-homepage/images/thumb/luma-logo.png",
    features: ["3D", "AR", "VP"],
    platform: "shopify",
    title: "Luma Furniture",
    subtitle: "Furniture & Home Goods",
    link:
      "https://furniture.demos.threekit.com/zeus-media-cabinet.html",
  },

  {
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/media/threekit-logos/threekit-icon.png",
    features: ["3D", "AR", "VP"],
    platform: "shopify",
    title: "Threekit x Magento",
    subtitle: "Learn more",
    link: "https://www.threekit.com/threekit-for-magento",
  },
];
export { DemoObject };
