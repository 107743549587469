import React from "react";
import "./Demo.css";
import "antd/dist/antd.css";
import { Icon, Card, List, Layout, Button } from "antd";
import { isMobile } from "react-device-detect";
import getPlatformList from "../../helpers/getPlatformList";
import {DemoObject} from '../../config/Configs'

class Products extends React.Component {
  render() {

    return (
      <div id="product-content">
        <List
          grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }}
          dataSource={DemoObject}
          pagination={true}
          //   loading={true}
          renderItem={(item) => (
            <List.Item>
              <Card
                cover={
                  <img
                    style={{
                      maxHeight: 200,
                      width: isMobile ? "auto" : "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={item.imageURL}
                  ></img>
                }
                actions={[
                  <Button href={item.link} target="_blank">
                    Launch
                  </Button>,
                ]}
              >
                <Card.Meta title={item.title} description={item.subtitle} />
              </Card>
            </List.Item>
          )}
        ></List>
      </div>
    );
  }
}

export default Products;
